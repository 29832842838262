import "core-js/modules/es.array.push.js";
import { get, post } from './../api';
import Recorder from 'js-audio-recorder';
import TWEEN from "@tweenjs/tween.js";
import { showToast } from 'vant';
import endPic from './../assets/end.png';
import beginPic from './../assets/begin.png';
var canvas = null;
var canvasContext = null;
var animationId = null;
var voiceId = null;
var showRecorderTimerId = null;
let recorder = new Recorder({
  sampleBits: 16,
  // 采样位数，支持 8 或 16，默认是16
  sampleRate: 16000,
  // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1 // 声道，支持 1 或 2， 默认是1
  // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
});

var touch = 'ontouchstart' in window || window.DocumentTouch;
export default {
  data() {
    return {
      urls: ['river', 'dialect', 'interact'],
      type: 'touch',
      //touch 触摸交互  voice 语音交互
      translate: 'dialect',
      //standard 方言转普通话  dialect 普通话转方言
      tencent: false,
      startX: 0,
      startY: 0,
      config: {},
      asr_loading: false,
      //语音识别中
      endPic: endPic,
      beginPic: beginPic,
      currentWord: {},
      recording: false,
      //是否在录音....
      voiceCount: 2,
      isPlay: 0,
      //如果音频 播放中  不让切换
      showLeft: true,
      showRight: true,
      FYduration: 0,
      percent: 0,
      PTHduration: 0,
      autoNext: false,
      to: '',
      notFound: false,
      cityList: [],
      words: [],
      cityId: 0,
      state: '',
      assetsUrl: window.assetsUrl,
      width: '',
      height: ''
    };
  },
  mounted() {
    document.title = '知识互动';
    window.addEventListener('resize', () => {
      this.getWindowInfo();
    });
    console.log('touch', touch);
    this.getWindowInfo();
    this.getHome();
    this.animateVoice();
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.getWindowInfo();
    });
    clearInterval(voiceId);
  },
  methods: {
    getWindowInfo() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    back() {
      this.$router.push('/');
    },
    myTween(type) {
      let vm = this;
      let duration = type == 'FY' ? this.FYduration : this.PTHduration;
      if (!duration) {
        setTimeout(() => {
          this.myTween(type);
        }, 50);
        return false;
      }
      const animate = function () {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      };
      new TWEEN.Tween({
        x: 0
      }).to({
        x: 100
      }, duration * 1000).onUpdate(object => {
        vm.percent = parseInt(object.x);
      }).start().onComplete(() => {
        vm.percent = 0;
      });
      animate();
    },
    toUrl(to) {
      this.to = to;
      setTimeout(() => {
        this.$router.push(to);
      }, 600);
    },
    changeType(val) {
      this.type = val;
      if (val == 'voice') {
        if (this.isPlay) {
          this.isPlay = 0;
        }
        // this.translate = 'dialect';
        this.currentWord = {};
      }
    },
    changeTranslate(val) {
      this.translate = val;
      // if(this.type == 'voice' && val == 'standard'){
      //   this.translate = 'dialect';
      //   showToast('方言转普通话功能尚未开放..');
      // }
    },

    selectCity(val) {
      if (val != this.cityId) {
        this.cityId = val;
        this.getDialect();
      }
    },
    onCanPlay(event, type) {
      if (type == 'FY') {
        this.FYduration = event.target.duration;
      } else {
        this.PTHduration = event.target.duration;
      }
    },
    animateVoice() {
      voiceId = setInterval(() => {
        if (this.voiceCount > 100) {
          this.voiceCount = 0;
        } else {
          this.voiceCount++;
        }
      }, 300);
    },
    onPlay(type) {
      if (this.translate == 'dialect') {
        this.showLeft = type == 'FY';
      } else {
        this.showLeft = type != 'FY';
      }
      this.showRight = !this.showLeft;
      this.myTween(type);
    },
    onPauseFY() {
      this.showLeft = true;
      this.showRight = true;
      this.isPlay--;
      if (this.autoNext) {
        this.autoNext = false;
        this.isPlay++;
        setTimeout(() => {
          this.$refs.audioPTH.play();
        }, 500);
      } else {
        this.showRecorder();
      }
    },
    play(au = 'FY') {
      if (this.isPlay > 0) {
        showToast('音频播放中...');
        return false;
      }
      this.isPlay++;
      clearTimeout(showRecorderTimerId);
      if (au == 'FY') {
        this.$refs.audioFY.play();
      } else {
        this.$refs.audioPTH.play();
      }
    },
    onPausePTH() {
      this.showLeft = true;
      this.showRight = true;
      this.isPlay--;
      if (this.autoNext) {
        this.isPlay++;
        this.autoNext = false;
        setTimeout(() => {
          this.$refs.audioFY.play();
        }, 500);
      } else {
        this.showRecorder();
      }
    },
    //录音播放完毕后 判断是否需要 延时 显示 录音
    showRecorder() {
      if (this.type == 'voice') {
        showRecorderTimerId = setTimeout(() => {
          this.currentWord = {};
          this.drawWaveform();
          this.notFound = false;
        }, 4000);
      }
    },
    selectWord(word) {
      if (this.isPlay > 0) {
        showToast('音频播放中...');
        return false;
      }
      this.currentWord = word;
      console.log(this.currentWord);
      this.$nextTick(() => {
        this.$refs.audioFY.src = word.media;
        this.$refs.audioPTH.src = word.standard.media;
        this.autoNext = true;
        this.play(this.translate != 'dialect' ? 'FY' : 'PTH');
      });
    },
    onTouchMove(e) {
      console.log('onTouchMove');
      if (!this.recording) {
        return false;
      }
      let startX = e.touches[0].clientX;
      let startY = e.touches[0].clientY;
      let diff = Math.abs(startX - this.startX) + Math.abs(startY - this.startY);
      console.log(diff);
      if (diff > 30) {
        this.onTouchEnd();
      }
    },
    onTouchCancel(type) {
      console.log(type);
      this.onTouchEnd();
    },
    onTouchEnd(type = 'system') {
      console.log('onTouchEnd' + type);
      if (!this.recording) {
        if (type == 'system') {
          setTimeout(() => {
            this.onTouchEnd('once');
          }, 200);
        }
        return false;
      }
      recorder.stop();
      this.recording = false;
      cancelAnimationFrame(animationId, () => {
        this.drawWaveform();
      });
      this.uploadVoice();
    },
    onTouchStart(e) {
      console.log('onTouchStart');
      if (this.recording) {
        return false;
      }
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
      recorder.start().then(() => {
        // 开始录音
        this.recording = true;
        canvas = document.getElementById("myCanvas");
        canvasContext = canvas.getContext('2d');
        animationId = requestAnimationFrame(() => {
          this.drawWaveform();
        });
      }, error => {
        // 出错了
        error && showToast(error.name == 'NotFoundError' ? '未找到麦克风设备!' : error.name);
      });
    },
    record() {
      // this.findWord('我到学校里找你的');return false;
      if (touch || this.recording) {
        return false;
      }
      console.log('record click');
      recorder.start().then(() => {
        // 开始录音
        this.recording = true;
        canvas = document.getElementById("myCanvas");
        canvasContext = canvas.getContext('2d');
        animationId = requestAnimationFrame(() => {
          this.drawWaveform();
        });
        setTimeout(() => {
          recorder.stop();
          this.recording = false;
          cancelAnimationFrame(animationId, () => {
            this.drawWaveform();
          });
          this.uploadVoice();
        }, 5000);
      }, error => {
        // 出错了
        error && showToast(error.name == 'NotFoundError' ? '未找到麦克风设备!' : error.name);
      });
    },
    uploadVoice() {
      const formData = new FormData();
      console.log(recorder.duration);
      if (recorder.duration <= 1) {
        showToast('请长按录音!');
        return false;
      }
      if (recorder.duration > 20) {
        showToast('录音时间过长,请控制在20秒以内!');
        return false;
      }
      if (this.tencent || this.translate == 'dialect') {
        formData.append('file', recorder.getWAVBlob());
        let type = this.cityId;
        formData.append('type', type);
        formData.append('translate', this.translate);
        this.asr_loading = true;
        post('asr', formData).then(response => {
          this.findWordById(response.data);
          // showToast(response.data.text + ' [' + response.data.score + ', ' + response.data.asr_text +']');
          this.asr_loading = false;
        });
        return false;
      }
      let url = 'http://127.0.0.1:8020/recongnize/';
      formData.append('file', recorder.getWAVBlob());
      let lang = 'wx';
      if (this.cityId == 2) {
        lang = 'yz';
      } else if (this.cityId == 3) {
        lang = 'tj';
      }
      formData.append('lang', lang);
      this.asr_loading = true;
      post(url, formData).then(response => {
        this.asr_loading = false;
        if (response.data.err_no > 0) {
          showToast('语音识别错误!' + response.data.err_msg);
        } else {
          let word = '';
          if (this.translate == 'standard') {
            word = response.data.label;
            // showToast(word + ' [' +  response.data.score.toFixed(2) + ']');
            if (response.data.score < 0.42) {
              word = '';
            }
          } else {
            word = response.data.result[0] || '';
            // showToast(word);
          }

          this.findWord(word);
        }
      });
    },
    likeWord(name, word) {
      if (word.length < 4) {
        return 0;
      }
      let count = 0;
      name.split('').forEach(char => {
        if (word.indexOf(char) !== -1) {
          count++;
        }
      });
      let like = 1.0 * count / name.length;
      console.log(name, count, like);
      return like;
    },
    findWordById(obj) {
      let find = false;
      if (obj.score < 0.42) {
        this.notFound = true;
        this.drawWaveform();
        return false;
      }
      //普通话 转 方言  查找普通话
      this.words.forEach(element => {
        let id = element.standard.id;
        // 方言  转 普通话  
        if (this.translate == 'standard') {
          id = element.id;
        }
        if (id == obj.id) {
          find = element;
        }
      });
      if (find) {
        this.selectWord(find);
      } else {
        this.notFound = true;
        this.drawWaveform();
      }
    },
    findWord(word) {
      word = word.replace('。', '').replace(',', '').replace('，', '').trim();
      console.log(word, word.length);
      let find = false;
      let lenDiff = 100; //字符串长度 最接近
      //普通话 转 方言  查找普通话
      this.words.forEach(element => {
        let name = element.standard.name;
        // 方言  转 普通话  
        if (this.translate == 'standard') {
          name = element.name;
        }
        name = name.replace('？', '').replace('。', '').replace('！', '').replace('，', '').replace(' ', '');
        name = name.replace('?', '').replace('.', '').replace('!', '').replace(',', '');
        let diff = 1.0 * Math.abs(name.length - word.length) / name.length;
        if (this.likeWord(name, word) >= 0.7 || word.indexOf(name) !== -1 && lenDiff > diff) {
          lenDiff = diff;
          find = element;
        }
      });
      if (find) {
        this.selectWord(find);
      } else {
        this.notFound = true;
        this.drawWaveform();
      }
    },
    drawWaveform() {
      if (!canvasContext) {
        console.log('canvasContext null');
        return false;
      }
      canvasContext.clearRect(0, 0, 500, 100);
      if (this.recording) {
        let dataArray = recorder.getRecordAnalyseData(),
          bufferLength = dataArray.length;
        // 填充背景色
        canvasContext.fillStyle = 'rgba(255, 255, 255, 0)';
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);
        // 设定波形绘制颜色
        canvasContext.lineWidth = 2;
        canvasContext.strokeStyle = 'rgb(0, 0, 0)';
        canvasContext.beginPath();
        var sliceWidth = canvas.width * 1.0 / bufferLength,
          // 一个点占多少位置，共有bufferLength个点要绘制
          x = 0; // 绘制点的x轴位置
        for (var i = 0; i < bufferLength; i++) {
          var v = dataArray[i] / 128.0;
          var y = v * canvas.height / 2;
          if (i === 0) {
            // 第一个点
            canvasContext.moveTo(x, y);
          } else {
            // 剩余的点
            canvasContext.lineTo(x, y);
          }
          // 依次平移，绘制所有点
          x += sliceWidth;
        }
        canvasContext.lineTo(canvas.width, canvas.height / 2);
        canvasContext.stroke();
        animationId = requestAnimationFrame(() => {
          this.drawWaveform();
        });
      }
    },
    getDialect() {
      get('dialect', {
        type: this.cityId
      }).then(res => {
        console.log(res);
        this.words = res.data.list;
      });
    },
    getHome() {
      get('type').then(res => {
        console.log(res);
        this.cityList = res.data;
        this.selectCity(res.data[0].id);
      });
      if (!this.tencent) {
        get('http://127.0.0.1:8020/').then(res => {
          console.log(res);
        });
      }
    }
  }
};